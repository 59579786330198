import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "21",
  viewBox: "0 0 20 21",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M15.8333 15.5V10.5C15.8333 10.04 16.2058 9.66667 16.6667 9.66667C17.1275 9.66667 17.5 10.04 17.5 10.5V15.5C17.5 16.8783 16.3783 18 15 18H5C3.62167 18 2.5 16.8783 2.5 15.5V5.5C2.5 4.12167 3.62167 3 5 3H10C10.4608 3 10.8333 3.37333 10.8333 3.83333C10.8333 4.29333 10.4608 4.66667 10 4.66667H5C4.54 4.66667 4.16667 5.04083 4.16667 5.5V15.5C4.16667 15.9592 4.54 16.3333 5 16.3333H15C15.46 16.3333 15.8333 15.9592 15.8333 15.5ZM14.6484 4.67333L13.3293 4.66667C12.8684 4.66417 12.4976 4.28917 12.5001 3.82917C12.5026 3.37 12.8751 3 13.3334 3H13.3376L16.6684 3.01667C17.1267 3.01917 17.4976 3.39083 17.4976 3.84917L17.5001 7.16583C17.5001 7.62667 17.1276 8 16.6676 8H16.6667C16.2067 8 15.8334 7.6275 15.8334 7.1675L15.8326 5.84583L10.5892 11.0892C10.4267 11.2517 10.2134 11.3333 10.0001 11.3333C9.78675 11.3333 9.57342 11.2517 9.41092 11.0892C9.08508 10.7633 9.08508 10.2367 9.41092 9.91083L14.6484 4.67333Z"
    }, null, -1)
  ])))
}
export default { render: render }